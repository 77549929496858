import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError } from "rxjs/internal/operators/catchError";
import { CommonFunctionService } from "./commonfunction.service";
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private zone: NgZone,
    private CommonService: CommonFunctionService
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const path: any = req.url.split('/');
    const url = path[path.length - 1];
    const IgnoreUrls = ['QuestionResponse', 'Token', 'post_image_url'];
    const request = (IgnoreUrls.includes(url));
    if (!request) {
      const token = localStorage.getItem('Prinsezeetoken');
      req = req.clone({
        setHeaders: { Authorization: 'bearer ' + token }
      });
    }
    return next.handle(req).pipe(
      catchError(err => {
        this.zone.run(() => {
          this.CommonService.toasterError('Session Expired', 'Error!');
          this.router.navigate(['/']);
          localStorage.removeItem(this.CommonService.tokenname);
        })
        return throwError(err);
      }));
  };
}
